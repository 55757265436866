.events {
	max-width: 900px;
	.list {
		@include grid(600px 2 2%, 800px 1 0, 950px 2 3%, 1200px 3 3%);
		&__item {
			@include min(600px) {
				@include max(1200px) {
					@include limit-items(2);
				}
			}
		}
	}

	.event {
		position: relative;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		padding-bottom: 100%;
		border-radius: 6px;
		background: #dedede;
		z-index: 10;

		@include min(600px) {
			padding-bottom: 130%;
		}

		&__image {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			@include fill();
			z-index: -1;

			img {
				position: absolute;
				width: auto;
				max-width: none;
				height: 100%;
				z-index: -1;
			}
		}

		&__day {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: 5;
			color: #fff;
			padding: .75rem .5rem 10%;
			text-align: center;
			font-size: rem(20px);
			background: linear-gradient(180deg, #000 25%, transparent 100%);

			&-fullday:not(:first-child) {
				&::before {
					content: " - ";
				}
			}

			&-month {
				text-transform: capitalize;
			}

			&-year {
				position: absolute !important;
				left: -9999rem !important;
				top: auto;
				outline: 0;
				width: 0;
				height: 0;
				overflow: hidden;
			}
		}

		&__body {
			position: absolute;
			@include flex(df, fdc, jcc);
			font-size: rem(14px);
			bottom: 0;
			left: 0;
			right: 0;
			color: #fff;
			margin: 0.5rem 0.5rem 0;
			padding-bottom: .5rem;
			z-index: 5;
			line-height: 1.25;

			&::before {
				z-index: -1;
				position: absolute;
				content: "";
				top: -2.5rem;
				left: -.5rem;
				right: -.5rem;
				bottom: 0;
				background: linear-gradient(0deg, #000, transparent 100%);
			}

			@include link() {
				color: #fff;
				border: 0;
				font-size: inherit;
			}

			@include link-over() {
				text-decoration: none;
			}
		}

		&__link {
			margin-bottom: 0;
		}

		&__title {
			font-size: rem(14px);
		}

		&__dates {
			border-color: #fff;
			padding-bottom: 0.5rem;
		}

		&__type {
			&__link {
				&::after {
					margin-left: .25rem;
				}
			}
		}

		&__status,
		&__description {
			position: absolute;
			@include flex(df, aic, jcc);
			@include fill;
			margin: 0;
			background-color: rgba(#000000, .6);
			font-size: rem(18px);
			z-index: 2;

			&,
			&.-primary {
				color: #fff;
			}
		}

		&__description {
			opacity: 0;
			z-index: 3;

			@include link-over(&) {
				opacity: 1;

				+.event__status {
					opacity: 0;
				}
			}
		}
	}
}

.events-by-day {
	.list {
		&__item {}
	}

	&__more {
		margin-top: 0;
		text-align: left;
	}

	.event {
		&__types {
			@include flex(df, fww, aic);
			gap: 0.25rem;
		}

		&__type {
			&__link {
				@include link-over(&) {
					text-decoration: underline;
				}
			}
		}
	}
}

.event {
	&-datetime {
		margin: 0;

		&__container {
			.list {
				margin-bottom: 1rem;
			}
		}

		&__description {
			margin: 0;
		}
	}

	&-thumb-desc {
		display: flex;
		flex-wrap: wrap;
		margin-top: 1rem;
		margin-bottom: 1rem;

		.article__image {
			margin: 0;
			padding: .4rem 0 0rem;
		}

		.event__description {
			.text-component p:first-child {
				margin-top: 0;
			}
		}

		@include min(1024px) {

			>.article__image,
			>div:first-child:not(.event__description) {
				max-width: 50%;
				padding-right: 6%;
			}

			.event__description {
				flex: 1;
			}
		}
	}

	&__day {
		color: $color-brand;
	}

	&__list {
		list-style-type: none;
		padding-left: 0;
	}

	&__item {
		margin-bottom: 1.5rem;
	}

	&__image {
		margin: 0;
	}

	&__body {
		position: relative;
		padding-left: 5em;
		min-height: 3.5em;

		.event__dates {
			width: 3.75em;
		}
	}

	&__link {
		display: inline-block;
		margin-bottom: 0.5rem;
	}

	&__title {
		font-size: rem(18px);
		display: inline;
	}

	&__types {
		line-height: 1.25;
	}

	&__description {
		margin-top: 0.5rem;
	}

	&__dates {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		flex-direction: column;
		border-right: 2px solid #a9a9a9;
	}

	&__status {
		display: block;
		margin-bottom: 0.5rem;
		color: #CA1517;
		font-size: rem(17px);

		&.-primary {
			color: $color-brand;
		}
	}
}

.article-event {
	&__perex {
		margin-top: .5em;
	}

	&__types {
		.list {
			display: flex;
			flex-wrap: wrap;
			gap: 8px 10px;
			margin: 0 0 1rem;

			&__item {
				display: inline-flex;
				margin: 0;
				line-height: 1.25;
				align-items: center;
			}
		}
	}
}

.article-event__type,
.event__type__link {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 1.845rem;
	min-height: 1.845rem;
	border: 2px solid #9D9D9D;
	border-radius: 6px;
	text-decoration: none;
	padding: 0 .5rem;
}
