@charset "UTF-8";
/*!
*  Author: WEBHOUSE, s. r. o.
*  Version: 1.0
*/
/*
* BASE DEPENDENCIES
*/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*************************
* MIXINY PRO ODRÁŽKY
*************************/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/*==============================
	DEFAULTS
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	TABS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*==============================
	CALENDAR -PAGE
==============================*/
@keyframes fade-into-foreground {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes fade-into-background {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.75);
    opacity: 0;
  }
}

@keyframes pop-into-foreground {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pop-into-foreground-with-bounce {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  72% {
    transform: scale(1.07);
    opacity: .72;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
  }
}

/*
* COMMON BLOCKS
*/
.calendar [role="button"]:focus-visible {
  outline: 3px lightskyblue solid;
  outline-offset: 2px;
  -moz-outline-radius: 3px;
}

/*==============================
	INPUT, TEXTAREA, SELECT
==============================*/
/*==============================
	CHECKBOX, SWITCH, RADIO
==============================*/
.events {
  max-width: 900px;
}

.events .list {
  display: grid;
}

@media screen and (min-width: 600px) and (max-width: 800px) {
  .events .list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2%;
    gap: 2%;
  }
}

@media screen and (min-width: 800px) and (max-width: 950px) {
  .events .list {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}

@media screen and (min-width: 950px) and (max-width: 1200px) {
  .events .list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3%;
    gap: 3%;
  }
}

@media screen and (min-width: 1199px) {
  .events .list {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3%;
    gap: 3%;
  }
}

@media screen and (min-width: 600px) and (max-width: 1200px) {
  .events .list__item:nth-of-type(2) ~ .list__item {
    display: none;
  }
}

.events .event {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 100%;
  border-radius: 6px;
  background: #dedede;
  z-index: 10;
}

@media screen and (min-width: 600px) {
  .events .event {
    padding-bottom: 130%;
  }
}

.events .event__image {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.events .event__image img {
  position: absolute;
  width: auto;
  max-width: none;
  height: 100%;
  z-index: -1;
}

.events .event__day {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  color: #fff;
  padding: .75rem .5rem 10%;
  text-align: center;
  font-size: 1.25rem;
  background: linear-gradient(180deg, #000 25%, transparent 100%);
}

.events .event__day-fullday:not(:first-child)::before {
  content: " - ";
}

.events .event__day-month {
  text-transform: capitalize;
}

.events .event__day-year {
  position: absolute !important;
  left: -9999rem !important;
  top: auto;
  outline: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.events .event__body {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.875rem;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  margin: 0.5rem 0.5rem 0;
  padding-bottom: .5rem;
  z-index: 5;
  line-height: 1.25;
}

.events .event__body::before {
  z-index: -1;
  position: absolute;
  content: "";
  top: -2.5rem;
  left: -.5rem;
  right: -.5rem;
  bottom: 0;
  background: linear-gradient(0deg, #000, transparent 100%);
}

.events .event__body a,
.events .event__body a:link,
.events .event__body a:visited {
  color: #fff;
  border: 0;
  font-size: inherit;
}

.events .event__body a:hover,
.events .event__body a:focus,
.events .event__body a:active {
  text-decoration: none;
}

.events .event__link {
  margin-bottom: 0;
}

.events .event__title {
  font-size: 0.875rem;
}

.events .event__dates {
  border-color: #fff;
  padding-bottom: 0.5rem;
}

.events .event__type__link::after {
  margin-left: .25rem;
}

.events .event__status, .events .event__description {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 1.125rem;
  z-index: 2;
}

.events .event__status, .events .event__status.-primary, .events .event__description, .events .event__description.-primary {
  color: #fff;
}

.events .event__description {
  opacity: 0;
  z-index: 3;
}

.events .event__description:hover, .events .event__description:focus, .events .event__description:active {
  opacity: 1;
}

.events .event__description:hover + .event__status, .events .event__description:focus + .event__status, .events .event__description:active + .event__status {
  opacity: 0;
}

.events-by-day__more {
  margin-top: 0;
  text-align: left;
}

.events-by-day .event__types {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.25rem;
}

.events-by-day .event__type__link:hover, .events-by-day .event__type__link:focus, .events-by-day .event__type__link:active {
  text-decoration: underline;
}

.event-datetime {
  margin: 0;
}

.event-datetime__container .list {
  margin-bottom: 1rem;
}

.event-datetime__description {
  margin: 0;
}

.event-thumb-desc {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.event-thumb-desc .article__image {
  margin: 0;
  padding: .4rem 0 0rem;
}

.event-thumb-desc .event__description .text-component p:first-child {
  margin-top: 0;
}

@media screen and (min-width: 1024px) {
  .event-thumb-desc > .article__image,
  .event-thumb-desc > div:first-child:not(.event__description) {
    max-width: 50%;
    padding-right: 6%;
  }
  .event-thumb-desc .event__description {
    flex: 1;
  }
}

.event__day {
  color: #162847;
}

.event__list {
  list-style-type: none;
  padding-left: 0;
}

.event__item {
  margin-bottom: 1.5rem;
}

.event__image {
  margin: 0;
}

.event__body {
  position: relative;
  padding-left: 5em;
  min-height: 3.5em;
}

.event__body .event__dates {
  width: 3.75em;
}

.event__link {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.event__title {
  font-size: 1.125rem;
  display: inline;
}

.event__types {
  line-height: 1.25;
}

.event__description {
  margin-top: 0.5rem;
}

.event__dates {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-right: 2px solid #a9a9a9;
}

.event__status {
  display: block;
  margin-bottom: 0.5rem;
  color: #CA1517;
  font-size: 1.0625rem;
}

.event__status.-primary {
  color: #162847;
}

.article-event__perex {
  margin-top: .5em;
}

.article-event__types .list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 10px;
  margin: 0 0 1rem;
}

.article-event__types .list__item {
  display: inline-flex;
  margin: 0;
  line-height: 1.25;
  align-items: center;
}

.article-event__type,
.event__type__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1.845rem;
  min-height: 1.845rem;
  border: 2px solid #9D9D9D;
  border-radius: 6px;
  text-decoration: none;
  padding: 0 .5rem;
}

.-type-id-28 {
  font-size: 0;
  padding: 0;
}

.-type-id-28:not(.-before)::after,
.-type-id-28.-before::before {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.644' height='11.41' viewBox='0 0 11.644 11.41'%3E%3Cpath id='Path_476' data-name='Path 476' d='M550.951,272.3l1.374,2.856a.55.55,0,0,0,.413.308l3.073.458a.565.565,0,0,1,.3.958l-2.224,2.223a.57.57,0,0,0-.157.5l.524,3.139a.55.55,0,0,1-.794.592l-2.749-1.482a.536.536,0,0,0-.511,0l-2.749,1.482a.55.55,0,0,1-.794-.592l.524-3.139a.57.57,0,0,0-.157-.5l-2.224-2.223a.565.565,0,0,1,.3-.958l3.073-.458a.55.55,0,0,0,.413-.308l1.375-2.856A.542.542,0,0,1,550.951,272.3Z' transform='translate(-544.638 -271.984)' fill='%23353b42'/%3E%3C/svg%3E");
}

.-type-id-30 {
  font-size: 0;
  padding: 0;
}

.-type-id-30:not(.-before)::after,
.-type-id-30.-before::before {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.238' height='11.248' viewBox='0 0 11.238 11.248' fill='%23353b42'%3E%3Cpath id='Path_477' data-name='Path 477' d='M564.564,274.771a5.567,5.567,0,0,1,6.855,0,4.259,4.259,0,0,1-6.855,0Z' transform='translate(-562.373 -273.59)'/%3E%3Cpath id='Path_478' data-name='Path 478' d='M571.419,282.654a5.6,5.6,0,0,1-6.855,0,4.244,4.244,0,0,1,6.855,0Z' transform='translate(-562.373 -272.576)'/%3E%3Cpath id='Path_479' data-name='Path 479' d='M573.877,278.987a5.575,5.575,0,0,1-1.451,3.762,5.259,5.259,0,0,0-8.335,0,5.6,5.6,0,0,1,0-7.525,5.259,5.259,0,0,0,8.335,0A5.575,5.575,0,0,1,573.877,278.987Z' transform='translate(-562.639 -273.363)'/%3E%3C/svg%3E");
}

.-type-id-31 {
  font-size: 0;
  padding: 0;
}

.-type-id-31:not(.-before)::after,
.-type-id-31.-before::before {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='11.942' viewBox='0 0 13 11.942'%3E%3Cpath id='Path_483' data-name='Path 483' d='M633.093,279.927v-1.763a.552.552,0,0,0-.552-.552h-1.3a2.007,2.007,0,0,0-.27-4,2.007,2.007,0,0,0-.27,4h-4.373a2.8,2.8,0,1,0-.705,0h-2.175a.552.552,0,0,0-.552.552v5.266a.552.552,0,0,0,.552.552h9.1a.552.552,0,0,0,.552-.552v-1.763l2.8,1.775v-5.289Zm-2.962-4.3a.835.835,0,1,1,.835.835A.838.838,0,0,1,630.131,275.625Zm-5.783-.788a1.628,1.628,0,1,1,1.622,1.622A1.625,1.625,0,0,1,624.347,274.837Z' transform='translate(-622.89 -272.04)' fill='%23353b42'/%3E%3C/svg%3E");
}

.-type-id-32 {
  font-size: 0;
  padding: 0;
}

.-type-id-32:not(.-before)::after,
.-type-id-32.-before::before {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.72' height='11.946' viewBox='0 0 11.72 11.946'%3E%3Cpath id='Path_480' data-name='Path 480' d='M588.6,272.79a7.278,7.278,0,0,1-10.7,0,11.772,11.772,0,0,0-.51,3.476c0,4.665,2.627,8.47,5.854,8.47s5.866-3.8,5.866-8.47A11.716,11.716,0,0,0,588.6,272.79Zm-8.991,4.79c0-.544.668-.985,1.495-.985s1.483.442,1.483.985-.668.974-1.483.974S579.609,278.123,579.609,277.58Zm3.646,5.662c-1.891,0-3.431-1.2-3.431-2.684a1.927,1.927,0,0,1,.113-.668,4.675,4.675,0,0,0,6.636,0,1.927,1.927,0,0,1,.113.668C586.687,282.041,585.158,283.242,583.256,283.242Zm2.14-4.688c-.827,0-1.495-.43-1.495-.974s.668-.985,1.495-.985,1.495.442,1.495.985S586.222,278.554,585.4,278.554Z' transform='translate(-577.39 -272.79)' fill='%23353b42'/%3E%3C/svg%3E");
}

.-type-id-33 {
  font-size: 0;
  padding: 0;
}

.-type-id-33:not(.-before)::after,
.-type-id-33.-before::before {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.213' height='16.084' viewBox='0 0 11.213 16.084'%3E%3Cpath id='Path_482' data-name='Path 482' d='M616.893,276.895h-.913c0-2.145-2.021-3.905-3.117-4.7v11.2a.657.657,0,0,1,.012.154,3.65,3.65,0,1,1-3.591-3.032,3.892,3.892,0,0,1,2.666,1V270.51l.682.385C612.8,271,616.893,273.4,616.893,276.895Z' transform='translate(-605.68 -270.51)' fill='%23353b42'/%3E%3C/svg%3E");
}

.-type-id-41 {
  font-size: 0;
  padding: 0;
}

.-type-id-41:not(.-before)::after,
.-type-id-41.-before::before {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.041' height='17' viewBox='0 0 9.041 17'%3E%3Cpath id='Path_481' data-name='Path 481' d='M599.158,281.927a3.1,3.1,0,0,1,1.325,1.952h-1.994c0,1.152-.21,3.538-1.889,4.27l-.38-.949c1.05-.447,1.286-2.223,1.286-3.321h-2.047a3.169,3.169,0,0,1,1.312-1.966,5.41,5.41,0,0,1-3.32-5.287c0-3.023,2.021-5.477,4.514-5.477s4.527,2.454,4.527,5.477A5.42,5.42,0,0,1,599.158,281.927Z' transform='translate(-593.45 -271.15)' fill='%23353b42'/%3E%3C/svg%3E");
}

.-type-id-28 {
  font-size: 0;
  padding: 0;
}

.code-list-items .-type-id-28::after {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.644' height='11.41' viewBox='0 0 11.644 11.41'%3E%3Cpath id='Path_476' data-name='Path 476' d='M550.951,272.3l1.374,2.856a.55.55,0,0,0,.413.308l3.073.458a.565.565,0,0,1,.3.958l-2.224,2.223a.57.57,0,0,0-.157.5l.524,3.139a.55.55,0,0,1-.794.592l-2.749-1.482a.536.536,0,0,0-.511,0l-2.749,1.482a.55.55,0,0,1-.794-.592l.524-3.139a.57.57,0,0,0-.157-.5l-2.224-2.223a.565.565,0,0,1,.3-.958l3.073-.458a.55.55,0,0,0,.413-.308l1.375-2.856A.542.542,0,0,1,550.951,272.3Z' transform='translate(-544.638 -271.984)' fill='%23ffffff'/%3E%3C/svg%3E");
}

.-type-id-30 {
  font-size: 0;
  padding: 0;
}

.code-list-items .-type-id-30::after {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.238' height='11.248' viewBox='0 0 11.238 11.248' fill='%23ffffff'%3E%3Cpath id='Path_477' data-name='Path 477' d='M564.564,274.771a5.567,5.567,0,0,1,6.855,0,4.259,4.259,0,0,1-6.855,0Z' transform='translate(-562.373 -273.59)'/%3E%3Cpath id='Path_478' data-name='Path 478' d='M571.419,282.654a5.6,5.6,0,0,1-6.855,0,4.244,4.244,0,0,1,6.855,0Z' transform='translate(-562.373 -272.576)'/%3E%3Cpath id='Path_479' data-name='Path 479' d='M573.877,278.987a5.575,5.575,0,0,1-1.451,3.762,5.259,5.259,0,0,0-8.335,0,5.6,5.6,0,0,1,0-7.525,5.259,5.259,0,0,0,8.335,0A5.575,5.575,0,0,1,573.877,278.987Z' transform='translate(-562.639 -273.363)'/%3E%3C/svg%3E");
}

.-type-id-31 {
  font-size: 0;
  padding: 0;
}

.code-list-items .-type-id-31::after {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='11.942' viewBox='0 0 13 11.942'%3E%3Cpath id='Path_483' data-name='Path 483' d='M633.093,279.927v-1.763a.552.552,0,0,0-.552-.552h-1.3a2.007,2.007,0,0,0-.27-4,2.007,2.007,0,0,0-.27,4h-4.373a2.8,2.8,0,1,0-.705,0h-2.175a.552.552,0,0,0-.552.552v5.266a.552.552,0,0,0,.552.552h9.1a.552.552,0,0,0,.552-.552v-1.763l2.8,1.775v-5.289Zm-2.962-4.3a.835.835,0,1,1,.835.835A.838.838,0,0,1,630.131,275.625Zm-5.783-.788a1.628,1.628,0,1,1,1.622,1.622A1.625,1.625,0,0,1,624.347,274.837Z' transform='translate(-622.89 -272.04)' fill='%23ffffff'/%3E%3C/svg%3E");
}

.-type-id-32 {
  font-size: 0;
  padding: 0;
}

.code-list-items .-type-id-32::after {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.72' height='11.946' viewBox='0 0 11.72 11.946'%3E%3Cpath id='Path_480' data-name='Path 480' d='M588.6,272.79a7.278,7.278,0,0,1-10.7,0,11.772,11.772,0,0,0-.51,3.476c0,4.665,2.627,8.47,5.854,8.47s5.866-3.8,5.866-8.47A11.716,11.716,0,0,0,588.6,272.79Zm-8.991,4.79c0-.544.668-.985,1.495-.985s1.483.442,1.483.985-.668.974-1.483.974S579.609,278.123,579.609,277.58Zm3.646,5.662c-1.891,0-3.431-1.2-3.431-2.684a1.927,1.927,0,0,1,.113-.668,4.675,4.675,0,0,0,6.636,0,1.927,1.927,0,0,1,.113.668C586.687,282.041,585.158,283.242,583.256,283.242Zm2.14-4.688c-.827,0-1.495-.43-1.495-.974s.668-.985,1.495-.985,1.495.442,1.495.985S586.222,278.554,585.4,278.554Z' transform='translate(-577.39 -272.79)' fill='%23ffffff'/%3E%3C/svg%3E");
}

.-type-id-33 {
  font-size: 0;
  padding: 0;
}

.code-list-items .-type-id-33::after {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.213' height='16.084' viewBox='0 0 11.213 16.084'%3E%3Cpath id='Path_482' data-name='Path 482' d='M616.893,276.895h-.913c0-2.145-2.021-3.905-3.117-4.7v11.2a.657.657,0,0,1,.012.154,3.65,3.65,0,1,1-3.591-3.032,3.892,3.892,0,0,1,2.666,1V270.51l.682.385C612.8,271,616.893,273.4,616.893,276.895Z' transform='translate(-605.68 -270.51)' fill='%23ffffff'/%3E%3C/svg%3E");
}

.-type-id-41 {
  font-size: 0;
  padding: 0;
}

.code-list-items .-type-id-41::after {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.041' height='17' viewBox='0 0 9.041 17'%3E%3Cpath id='Path_481' data-name='Path 481' d='M599.158,281.927a3.1,3.1,0,0,1,1.325,1.952h-1.994c0,1.152-.21,3.538-1.889,4.27l-.38-.949c1.05-.447,1.286-2.223,1.286-3.321h-2.047a3.169,3.169,0,0,1,1.312-1.966,5.41,5.41,0,0,1-3.32-5.287c0-3.023,2.021-5.477,4.514-5.477s4.527,2.454,4.527,5.477A5.42,5.42,0,0,1,599.158,281.927Z' transform='translate(-593.45 -271.15)' fill='%23ffffff'/%3E%3C/svg%3E");
}

.-type-id-28 {
  font-size: 0;
  padding: 0;
}

.-selected.-type-id-28::after {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.644' height='11.41' viewBox='0 0 11.644 11.41'%3E%3Cpath id='Path_476' data-name='Path 476' d='M550.951,272.3l1.374,2.856a.55.55,0,0,0,.413.308l3.073.458a.565.565,0,0,1,.3.958l-2.224,2.223a.57.57,0,0,0-.157.5l.524,3.139a.55.55,0,0,1-.794.592l-2.749-1.482a.536.536,0,0,0-.511,0l-2.749,1.482a.55.55,0,0,1-.794-.592l.524-3.139a.57.57,0,0,0-.157-.5l-2.224-2.223a.565.565,0,0,1,.3-.958l3.073-.458a.55.55,0,0,0,.413-.308l1.375-2.856A.542.542,0,0,1,550.951,272.3Z' transform='translate(-544.638 -271.984)' fill='%23292A2D'/%3E%3C/svg%3E");
}

.-type-id-30 {
  font-size: 0;
  padding: 0;
}

.-selected.-type-id-30::after {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.238' height='11.248' viewBox='0 0 11.238 11.248' fill='%23292A2D'%3E%3Cpath id='Path_477' data-name='Path 477' d='M564.564,274.771a5.567,5.567,0,0,1,6.855,0,4.259,4.259,0,0,1-6.855,0Z' transform='translate(-562.373 -273.59)'/%3E%3Cpath id='Path_478' data-name='Path 478' d='M571.419,282.654a5.6,5.6,0,0,1-6.855,0,4.244,4.244,0,0,1,6.855,0Z' transform='translate(-562.373 -272.576)'/%3E%3Cpath id='Path_479' data-name='Path 479' d='M573.877,278.987a5.575,5.575,0,0,1-1.451,3.762,5.259,5.259,0,0,0-8.335,0,5.6,5.6,0,0,1,0-7.525,5.259,5.259,0,0,0,8.335,0A5.575,5.575,0,0,1,573.877,278.987Z' transform='translate(-562.639 -273.363)'/%3E%3C/svg%3E");
}

.-type-id-31 {
  font-size: 0;
  padding: 0;
}

.-selected.-type-id-31::after {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='11.942' viewBox='0 0 13 11.942'%3E%3Cpath id='Path_483' data-name='Path 483' d='M633.093,279.927v-1.763a.552.552,0,0,0-.552-.552h-1.3a2.007,2.007,0,0,0-.27-4,2.007,2.007,0,0,0-.27,4h-4.373a2.8,2.8,0,1,0-.705,0h-2.175a.552.552,0,0,0-.552.552v5.266a.552.552,0,0,0,.552.552h9.1a.552.552,0,0,0,.552-.552v-1.763l2.8,1.775v-5.289Zm-2.962-4.3a.835.835,0,1,1,.835.835A.838.838,0,0,1,630.131,275.625Zm-5.783-.788a1.628,1.628,0,1,1,1.622,1.622A1.625,1.625,0,0,1,624.347,274.837Z' transform='translate(-622.89 -272.04)' fill='%23292A2D'/%3E%3C/svg%3E");
}

.-type-id-32 {
  font-size: 0;
  padding: 0;
}

.-selected.-type-id-32::after {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.72' height='11.946' viewBox='0 0 11.72 11.946'%3E%3Cpath id='Path_480' data-name='Path 480' d='M588.6,272.79a7.278,7.278,0,0,1-10.7,0,11.772,11.772,0,0,0-.51,3.476c0,4.665,2.627,8.47,5.854,8.47s5.866-3.8,5.866-8.47A11.716,11.716,0,0,0,588.6,272.79Zm-8.991,4.79c0-.544.668-.985,1.495-.985s1.483.442,1.483.985-.668.974-1.483.974S579.609,278.123,579.609,277.58Zm3.646,5.662c-1.891,0-3.431-1.2-3.431-2.684a1.927,1.927,0,0,1,.113-.668,4.675,4.675,0,0,0,6.636,0,1.927,1.927,0,0,1,.113.668C586.687,282.041,585.158,283.242,583.256,283.242Zm2.14-4.688c-.827,0-1.495-.43-1.495-.974s.668-.985,1.495-.985,1.495.442,1.495.985S586.222,278.554,585.4,278.554Z' transform='translate(-577.39 -272.79)' fill='%23292A2D'/%3E%3C/svg%3E");
}

.-type-id-33 {
  font-size: 0;
  padding: 0;
}

.-selected.-type-id-33::after {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.213' height='16.084' viewBox='0 0 11.213 16.084'%3E%3Cpath id='Path_482' data-name='Path 482' d='M616.893,276.895h-.913c0-2.145-2.021-3.905-3.117-4.7v11.2a.657.657,0,0,1,.012.154,3.65,3.65,0,1,1-3.591-3.032,3.892,3.892,0,0,1,2.666,1V270.51l.682.385C612.8,271,616.893,273.4,616.893,276.895Z' transform='translate(-605.68 -270.51)' fill='%23292A2D'/%3E%3C/svg%3E");
}

.-type-id-41 {
  font-size: 0;
  padding: 0;
}

.-selected.-type-id-41::after {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.041' height='17' viewBox='0 0 9.041 17'%3E%3Cpath id='Path_481' data-name='Path 481' d='M599.158,281.927a3.1,3.1,0,0,1,1.325,1.952h-1.994c0,1.152-.21,3.538-1.889,4.27l-.38-.949c1.05-.447,1.286-2.223,1.286-3.321h-2.047a3.169,3.169,0,0,1,1.312-1.966,5.41,5.41,0,0,1-3.32-5.287c0-3.023,2.021-5.477,4.514-5.477s4.527,2.454,4.527,5.477A5.42,5.42,0,0,1,599.158,281.927Z' transform='translate(-593.45 -271.15)' fill='%23292A2D'/%3E%3C/svg%3E");
}

.-type-id-28 {
  font-size: 0;
  padding: 0;
}

.events .-type-id-28::after {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.644' height='11.41' viewBox='0 0 11.644 11.41'%3E%3Cpath id='Path_476' data-name='Path 476' d='M550.951,272.3l1.374,2.856a.55.55,0,0,0,.413.308l3.073.458a.565.565,0,0,1,.3.958l-2.224,2.223a.57.57,0,0,0-.157.5l.524,3.139a.55.55,0,0,1-.794.592l-2.749-1.482a.536.536,0,0,0-.511,0l-2.749,1.482a.55.55,0,0,1-.794-.592l.524-3.139a.57.57,0,0,0-.157-.5l-2.224-2.223a.565.565,0,0,1,.3-.958l3.073-.458a.55.55,0,0,0,.413-.308l1.375-2.856A.542.542,0,0,1,550.951,272.3Z' transform='translate(-544.638 -271.984)' fill='%23fff'/%3E%3C/svg%3E");
}

.-type-id-30 {
  font-size: 0;
  padding: 0;
}

.events .-type-id-30::after {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.238' height='11.248' viewBox='0 0 11.238 11.248' fill='%23fff'%3E%3Cpath id='Path_477' data-name='Path 477' d='M564.564,274.771a5.567,5.567,0,0,1,6.855,0,4.259,4.259,0,0,1-6.855,0Z' transform='translate(-562.373 -273.59)'/%3E%3Cpath id='Path_478' data-name='Path 478' d='M571.419,282.654a5.6,5.6,0,0,1-6.855,0,4.244,4.244,0,0,1,6.855,0Z' transform='translate(-562.373 -272.576)'/%3E%3Cpath id='Path_479' data-name='Path 479' d='M573.877,278.987a5.575,5.575,0,0,1-1.451,3.762,5.259,5.259,0,0,0-8.335,0,5.6,5.6,0,0,1,0-7.525,5.259,5.259,0,0,0,8.335,0A5.575,5.575,0,0,1,573.877,278.987Z' transform='translate(-562.639 -273.363)'/%3E%3C/svg%3E");
}

.-type-id-31 {
  font-size: 0;
  padding: 0;
}

.events .-type-id-31::after {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='11.942' viewBox='0 0 13 11.942'%3E%3Cpath id='Path_483' data-name='Path 483' d='M633.093,279.927v-1.763a.552.552,0,0,0-.552-.552h-1.3a2.007,2.007,0,0,0-.27-4,2.007,2.007,0,0,0-.27,4h-4.373a2.8,2.8,0,1,0-.705,0h-2.175a.552.552,0,0,0-.552.552v5.266a.552.552,0,0,0,.552.552h9.1a.552.552,0,0,0,.552-.552v-1.763l2.8,1.775v-5.289Zm-2.962-4.3a.835.835,0,1,1,.835.835A.838.838,0,0,1,630.131,275.625Zm-5.783-.788a1.628,1.628,0,1,1,1.622,1.622A1.625,1.625,0,0,1,624.347,274.837Z' transform='translate(-622.89 -272.04)' fill='%23fff'/%3E%3C/svg%3E");
}

.-type-id-32 {
  font-size: 0;
  padding: 0;
}

.events .-type-id-32::after {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.72' height='11.946' viewBox='0 0 11.72 11.946'%3E%3Cpath id='Path_480' data-name='Path 480' d='M588.6,272.79a7.278,7.278,0,0,1-10.7,0,11.772,11.772,0,0,0-.51,3.476c0,4.665,2.627,8.47,5.854,8.47s5.866-3.8,5.866-8.47A11.716,11.716,0,0,0,588.6,272.79Zm-8.991,4.79c0-.544.668-.985,1.495-.985s1.483.442,1.483.985-.668.974-1.483.974S579.609,278.123,579.609,277.58Zm3.646,5.662c-1.891,0-3.431-1.2-3.431-2.684a1.927,1.927,0,0,1,.113-.668,4.675,4.675,0,0,0,6.636,0,1.927,1.927,0,0,1,.113.668C586.687,282.041,585.158,283.242,583.256,283.242Zm2.14-4.688c-.827,0-1.495-.43-1.495-.974s.668-.985,1.495-.985,1.495.442,1.495.985S586.222,278.554,585.4,278.554Z' transform='translate(-577.39 -272.79)' fill='%23fff'/%3E%3C/svg%3E");
}

.-type-id-33 {
  font-size: 0;
  padding: 0;
}

.events .-type-id-33::after {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.213' height='16.084' viewBox='0 0 11.213 16.084'%3E%3Cpath id='Path_482' data-name='Path 482' d='M616.893,276.895h-.913c0-2.145-2.021-3.905-3.117-4.7v11.2a.657.657,0,0,1,.012.154,3.65,3.65,0,1,1-3.591-3.032,3.892,3.892,0,0,1,2.666,1V270.51l.682.385C612.8,271,616.893,273.4,616.893,276.895Z' transform='translate(-605.68 -270.51)' fill='%23fff'/%3E%3C/svg%3E");
}

.-type-id-41 {
  font-size: 0;
  padding: 0;
}

.events .-type-id-41::after {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.041' height='17' viewBox='0 0 9.041 17'%3E%3Cpath id='Path_481' data-name='Path 481' d='M599.158,281.927a3.1,3.1,0,0,1,1.325,1.952h-1.994c0,1.152-.21,3.538-1.889,4.27l-.38-.949c1.05-.447,1.286-2.223,1.286-3.321h-2.047a3.169,3.169,0,0,1,1.312-1.966,5.41,5.41,0,0,1-3.32-5.287c0-3.023,2.021-5.477,4.514-5.477s4.527,2.454,4.527,5.477A5.42,5.42,0,0,1,599.158,281.927Z' transform='translate(-593.45 -271.15)' fill='%23fff'/%3E%3C/svg%3E");
}

.article__body.-calendar {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: "top-events" "calendar" "events";
  grid-gap: 0 2rem;
  gap: 0 2rem;
}

@media screen and (min-width: 1100px) {
  .article__body.-calendar {
    grid-template-columns: 1fr 300px;
    grid-template-areas: "top-events top-events" "events calendar" "links-gal links-gal";
  }
  .article__body.-calendar .calendar-sticky.-sticked {
    position: fixed;
    top: 20px;
  }
}

@media screen and (min-width: 1280px) {
  .article__body.-calendar {
    grid-template-columns: 1fr 320px;
  }
}

.article__body.-calendar > .dev-element-block:first-of-type {
  grid-area: top-events;
  padding-bottom: 2rem;
}

.article__body.-calendar > .dev-element-block:nth-of-type(2) {
  grid-area: events;
}

.article__body.-calendar > .dev-element-block:last-of-type {
  grid-area: links-gal;
}

.code-list-items__list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 -.25rem;
  list-style-type: none;
}

.calendar-type__item {
  padding: 0 .25rem .5rem;
}

.calendar-type__item a,
.calendar-type__item a:link,
.calendar-type__item a:visited {
  text-decoration: none;
}

.calendar-type__item a:hover,
.calendar-type__item a:focus,
.calendar-type__item a:active {
  text-decoration: underline;
}

.calendar-type__link {
  display: inline-flex;
  padding: 0.25rem 0.75rem 0.25rem;
  line-height: 1;
  align-items: center;
  justify-content: center;
  background-color: #373737;
  border-radius: 6px;
  min-height: 2em;
  font-size: 0.875rem;
}

.calendar-type__link::after {
  position: relative;
  top: 1px;
  display: flex;
  margin-right: 0rem;
  justify-content: center;
  margin-left: .5rem;
}

.calendar-type__link, .calendar-type__link:link, .calendar-type__link:visited {
  color: #ffffff;
}

.calendar-type__link.-selected {
  background-color: #fbd834;
}

.calendar-type__link.-selected, .calendar-type__link.-selected:link, .calendar-type__link.-selected:visited {
  color: #292A2D;
}

.calendar {
  font-size: 0.875rem;
  background-color: #fff;
  padding-bottom: 5px;
}

.calendar__container {
  position: relative;
  grid-area: calendar;
}

.calendar__table {
  margin: 2em auto 1em;
}

.calendar td,
.calendar th,
.calendar tr,
.calendar tr:nth-child(odd) {
  border-bottom: 0;
  background-color: #fff;
}

.calendar td,
.calendar th {
  text-align: center;
  min-width: 0;
  padding: 0.35em 0.15em;
}

.calendar th {
  padding: 1em 0.35em 0.35em;
  color: #373737;
  font-size: 1.0625rem;
  font-weight: 700;
}

.calendar [role="button"] {
  cursor: pointer;
}

.calendar [role="button"]:hover {
  text-decoration: underline;
}

.calendar [tabindex="-1"] {
  cursor: not-allowed;
}

.calendar .monthSelector {
  background-color: #fff !important;
}

.calendar .monthSelector__month {
  font-size: 1.0625rem;
  text-transform: uppercase;
  font-weight: 700;
}

.calendar .monthSelector__link span {
  font-size: 0;
}

.calendar .monthSelector__link span::before {
  display: inline-block;
  vertical-align: middle;
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='9' viewBox='0 0 7 9'%3E%3Cpath id='Polygon_2' data-name='Polygon 2' d='M4.5,0,9,7H0Z' transform='translate(7) rotate(90)' fill='%239d9d9d'/%3E%3C/svg%3E");
}

.calendar .monthSelector__link.-prev span::before {
  transform: rotate(180deg);
}

.calendar .monthSelector__link, .calendar .monthSelector__month {
  padding-top: 1em;
  padding-bottom: 1em;
}

.calendar__day {
  background-color: #f8f8f8;
  color: #242424;
  border: 0;
}

.calendar__day > span {
  display: block;
  min-height: 3em;
  padding: 0.3em 0.75em 0.5em;
  border: 2px solid transparent;
  border-radius: 10px;
}

.calendar__day[role="button"] {
  font-weight: 700;
}

.calendar__day[role="button"] > span:after {
  content: "";
  display: block;
  width: .25rem;
  height: .25rem;
  border-radius: 50%;
  background-color: #353b42;
  margin: 0 auto;
}

.calendar__day.-dayInPrevMonth > span {
  color: #979797 !important;
}

.calendar__day.-dayInPrevMonth > span:after {
  background-color: #979797 !important;
}

.calendar__day.-dayInNextMonth > span {
  color: #979797 !important;
}

.calendar__day.-dayInNextMonth > span:after {
  background-color: #979797 !important;
}

.calendar__day.-today {
  font-weight: 700;
}

.calendar__day.-today > span {
  border-color: #003fcf;
  color: #003fcf;
}

.calendar__day.-today > span:after {
  background-color: #003fcf;
}

.calendar__day.-selected > span {
  color: #fbd834;
  border-color: #fbd834;
}

.calendar__day.-selected > span:after {
  background-color: #fbd834;
}

.document {
  font-size: 1rem;
  margin: 0 0 .5em;
  display: flex;
  align-items: flex-start;
  /*&__date {
      display: block;
	}*/
}

.document__image {
  display: none;
  max-width: 240px;
  margin: 0 2em 0 0;
  flex-shrink: 0;
}

@media screen and (min-width: 600px) {
  .document__image {
    display: inline-block;
    float: left;
  }
}

.document__body {
  width: 100%;
}

.-board .document__body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.-board .document__body .to_board__link {
  margin-left: auto;
  align-self: flex-end;
}

.document__title {
  margin: 0 0 .15em;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 0;
}

.board_document {
  margin: 0 0 1.5em;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.board_document__image {
  display: none;
  max-width: 240px;
  margin-right: 2em;
  flex-shrink: 0;
}

@media screen and (min-width: 600px) {
  .board_document__image {
    display: inline-block;
    float: left;
  }
}

.board_document__body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 1em;
}

.board_document__body .to_board__link {
  margin-left: auto;
  align-self: flex-end;
  line-height: 2;
  padding-left: 1em;
}

.board_document__title {
  margin: 0 0 .15em;
  font-size: 1.5rem;
  font-weight: normal;
  padding: 0;
}

.site_board {
  font-size: 0.875rem;
  width: 100%;
}

.site_board__image {
  display: none;
  max-width: 240px;
  margin-right: 2em;
  flex-shrink: 0;
}

@media screen and (min-width: 600px) {
  .site_board__image {
    display: inline-block;
    float: left;
  }
}

.site_board__title {
  margin: 0 0 .15em;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0 0 .5em;
}

.board_document .site_board__title {
  font-size: 1.0625rem;
}

.site_board__description {
  margin: 0 0 1em;
  font-size: 0.9375rem;
}

.board_document .site_board__description {
  font-size: 0.875rem;
}

.site_board__list,
.list .site_board__list {
  padding: 0;
  margin: 0 0 1.5em;
}

.site_board__item {
  display: flex;
  padding: 1.5em 1.5em;
  flex-direction: column;
}

.site_board__item:nth-child(odd) {
  background-color: #D2DBE3;
}

.list__item .site_board__item .site_board__folder {
  display: none;
}

@media screen and (min-width: 600px) {
  .site_board__item {
    flex-direction: row;
  }
}

@media screen and (min-width: 800px) {
  .site_board__item {
    flex-direction: column;
  }
}

@media screen and (min-width: 1024px) {
  .site_board__item {
    flex-direction: row;
  }
}

.site_board__container.-dates {
  margin-left: auto;
  padding: 0.75em 0 0 2em;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .site_board__container.-dates {
    padding: 0 0 0 2em;
  }
}

.site_board__date {
  white-space: nowrap;
}

.article__body .list.documents {
  display: flex;
  flex-direction: column;
}

.article__body .list.documents .list__item {
  float: left;
  clear: both;
  font-size: 0;
  margin: 0 0 12px;
}

.document__link,
.document__link:link {
  color: #162847;
  text-decoration: underline;
}

.document__link .document__title,
.document__link:link .document__title {
  color: #162847;
}

.document__link:visited {
  color: #0a1220;
}

.document__link:visited .document__title {
  color: #0a1220;
}

.document__link:hover,
.document__link:focus,
.document__link:active {
  text-decoration: none;
}

.site_board__link:hover,
.site_board__link:focus,
.site_board__link:active {
  text-decoration: none;
}

/*
* DESIGN COMPONENTS
*/
.tags {
  list-style-type: none;
  padding-left: 0;
}

.tags__item {
  display: inline-block;
  vertical-align: top;
  margin: 0 .35em .35em 0;
}

.tags__link {
  text-decoration: none;
  display: inline-block;
  border-radius: 30px;
  padding: 6px 12px;
}

.tags__link,
.tags__link:link,
.tags__link:visited {
  color: #ffffff;
  text-decoration: none;
  background-color: #162847;
  background-repeat: repeat-x;
  background-size: auto 200%;
  background-position: 0 0;
}

.tags__link:hover,
.tags__link:focus,
.tags__link:active {
  background-color: #003fcf;
  color: #ffffff;
}

.tiles__list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 3rem 0 2rem;
  list-style-type: none;
  display: grid;
}

@media screen and (min-width: 500px) and (max-width: 1024px) {
  .tiles__list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
}

@media screen and (min-width: 1023px) {
  .tiles__list {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
}

.-nosidebar .tiles__list {
  display: grid;
}

@media screen and (min-width: 500px) and (max-width: 1024px) {
  .-nosidebar .tiles__list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
}

@media screen and (min-width: 1023px) {
  .-nosidebar .tiles__list {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
}

.tile__item {
  width: 100%;
  font-size: 1.25rem;
  font-weight: 800;
}

.tile__link {
  position: relative;
  padding: 1.25rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  text-align: center;
  transition: background-color .3s ease-in-out, color .3s ease-in-out, box-shadow .3s ease-in-out;
  box-shadow: 0 0 0 -25px;
}

@media screen and (min-width: 1024px) {
  .tile__link {
    min-height: 132px;
  }
}

.tile__link, .tile__link:link, .tile__link:visited {
  background-color: #fff;
  color: #003fcf;
  text-decoration: none;
}

.tile__link:hover, .tile__link:focus, .tile__link:active {
  background-color: #fbd834;
  color: #292A2D;
  box-shadow: 0px 35px 25px -25px rgba(0, 0, 0, 0.25);
}

.gallery {
  list-style-type: none;
  margin: 0 0 2em;
  padding: 0;
  grid-gap: 25px;
}

.gallery__item {
  position: relative;
}

.gallery__link {
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  text-decoration: none;
}

.gallery__link[data-image-desc]::after {
  content: attr(data-image-desc);
  line-height: 1.3;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(0);
  opacity: 0;
  padding: .7em 1em;
  color: #ffffff;
  transition: backdrop-filter .2s ease-out, opacity .25s ease-out;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gallery__link[data-image-desc]:hover::after {
  opacity: 1;
  backdrop-filter: blur(12px);
}

.gallery__link[data-image-desc]:focus-within::after {
  opacity: 1;
  backdrop-filter: blur(12px);
}

.gallery__image {
  object-fit: cover;
  flex-grow: 1;
  transition: transform .3s ease-out;
  transform: translate3d(0, 0, 1px) scale(1);
}

.gallery__link:not(.-placeholder):hover, .gallery__link:not(.-placeholder):focus, .gallery__link:not(.-placeholder):active {
  z-index: 2;
}

.gallery__link:not(.-placeholder):hover .gallery__image, .gallery__link:not(.-placeholder):focus .gallery__image, .gallery__link:not(.-placeholder):active .gallery__image {
  transform: translate3d(0, 0, 1px) scale(1.15);
}

.gallery__link.-placeholder {
  background-color: #eee;
}

.abo__form .label {
  position: absolute !important;
  left: -9999rem !important;
  top: auto;
  outline: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.abo__form .input {
  background-color: #ffffff;
  border-color: #292A2D;
  border-width: 0;
  color: #292A2D;
  min-height: 55px;
  box-shadow: 0 0 0;
  max-width: 380px;
}

.abo__form .gdpr-agreement {
  margin: 0;
}

.abo__form .button.-primary {
  background-color: transparent;
  padding: 0;
  min-height: 0;
  font-weight: 700;
}

.abo__form .button.-primary:hover, .abo__form .button.-primary:focus, .abo__form .button.-primary:active {
  background-color: transparent;
  text-decoration: underline;
}

.org-contacts__contact span:not(:first-of-type)::before {
  background-color: #003fcf;
}

.pagination {
  margin: 0 0 3em 0;
}

.pagination__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.pagination__item {
  display: inline;
}

.pagination__item:not(:first-child) {
  margin-left: .5em;
}

.pagination__item.-first::before {
  content: "\007c\003c ";
}

.pagination__item.-previous::before {
  content: "\003c\003c ";
}

.pagination__item.-next::after {
  content: " \003e\003e";
}

.pagination__item.-last::after {
  content: " \003e\007c";
}

.pagination__link[aria-disabled='true'],
.pagination__link[aria-disabled='true']:link,
.pagination__link[aria-disabled='true']:visited,
.pagination__link[aria-disabled='true']:hover,
.pagination__link[aria-disabled='true']:focus,
.pagination__link[aria-disabled='true']:active {
  pointer-events: none;
  text-decoration: none;
  opacity: .65;
}

.attachments {
  margin: 0 0 3em;
  grid-template-columns: 1fr;
  grid-gap: 1rem 0;
  padding: 0;
}

.attachments .vismo-context-button.-edit {
  top: 0;
  left: -2.5rem;
  background-color: #C1C1C1;
  width: 20px;
  padding: 0.3rem 0.3rem 0rem;
  box-sizing: content-box;
  border-radius: 5px 5px 0 0;
}

.attachments .vismo-context-button.-edit svg {
  height: 18px;
  filter: none;
}

.attachments [data-group-element-id] .vismo-checkbox {
  top: 1.75em;
  left: -2.5rem;
  background-color: #C1C1C1;
  width: 20px;
  padding: 0 0.3rem 0.2rem;
  box-sizing: content-box;
  border-radius: 0 0 5px 5px;
}

.attachments [data-group-element-id] .vismo-checkbox .vismo-checkbox__label:before,
.attachments [data-group-element-id] .vismo-checkbox .vismo-checkbox__label:after {
  width: 20px;
  height: 20px;
  margin-top: -10px;
}

.attachments [data-group-element-id] .vismo-checkbox :not(:checked) + .vismo-checkbox__label:before {
  box-shadow: 0 0 0;
}

.attachment {
  text-align: left;
}

.attachment__link {
  display: inline-flex;
  position: relative;
  flex-direction: column;
  padding-left: calc(2rem + 1rem);
}

.attachment__link::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 2rem;
  height: 3rem;
  background-color: #EEEEEE;
  border-radius: 0;
  margin-bottom: .75em;
  clip-path: polygon(0 0, 1.25rem 0, 2rem 0.75rem, 100% 100%, 0 100%, 0 0);
}

.attachment__link::after {
  content: "";
  border-radius: 0 0 0 0;
  width: 0.75rem;
  height: 0.75rem;
  background-color: #C1C1C1;
  position: absolute;
  left: 1.25rem;
  top: 0;
  z-index: 2;
  clip-path: polygon(0 0, 100% 100%, 0 100%, 0 0);
}

.attachment__title {
  display: block;
  word-wrap: break-word;
  margin: 0;
  font-weight: 700;
}

.attachment__type {
  text-transform: uppercase;
}

.attachment__type::after {
  content: ",";
}

.attachment__details:not(:first-child) {
  position: relative;
  margin: 0 0 0;
  color: #646464;
}

.attachment__details:not(:first-child)::before {
  content: "(";
}

.attachment__details:not(:first-child)::after {
  content: ")";
}

.attachment__link[data-attachment-type=""] {
  min-height: 2rem;
}

.attachment__link[data-attachment-type=""] .attachment__details:not(:first-child)::before, .attachment__link[data-attachment-type=""] .attachment__details:not(:first-child)::after,
.attachment__link[data-attachment-type=""] .attachment__type::before,
.attachment__link[data-attachment-type=""] .attachment__type::after {
  content: normal;
}

.attachment__link[data-attachment-type=""]::after {
  content: normal;
}

.attachment__link[data-attachment-type=""]::before {
  margin: 0;
  width: 2rem;
  height: 2rem;
  clip-path: none;
  border-radius: 0;
  background-image: url("data:image/svg+xml;utf8,%3Csvg id='Component_5_1' data-name='Component 5 – 1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cg id='Ellipse_31' data-name='Ellipse 31' fill='none' stroke='%23c1c1c1' stroke-width='4'%3E%3Cellipse cx='16' cy='16' rx='16' ry='16' stroke='none'/%3E%3Cellipse cx='16' cy='16' rx='14' ry='14' fill='none'/%3E%3C/g%3E%3Crect id='Rectangle_18' data-name='Rectangle 18' width='3.2' height='14.874' transform='translate(14.995 8.563)' fill='%23c1c1c1'/%3E%3Crect id='Rectangle_19' data-name='Rectangle 19' width='3.2' height='14.874' transform='translate(23.734 14.4) rotate(90)' fill='%23c1c1c1'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: transparent;
}

.attachment__link[data-attachment-type=""]:hover::before {
  background-image: url("data:image/svg+xml;utf8,%3Csvg id='Component_5_1' data-name='Component 5 – 1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cg id='Ellipse_31' data-name='Ellipse 31' fill='none' stroke='rgb(220,24,52)' stroke-width='4'%3E%3Cellipse cx='16' cy='16' rx='16' ry='16' stroke='none'/%3E%3Cellipse cx='16' cy='16' rx='14' ry='14' fill='none'/%3E%3C/g%3E%3Crect id='Rectangle_18' data-name='Rectangle 18' width='3.2' height='14.874' transform='translate(14.995 8.563)' fill='rgb(220,24,52)'/%3E%3Crect id='Rectangle_19' data-name='Rectangle 19' width='3.2' height='14.874' transform='translate(23.734 14.4) rotate(90)' fill='rgb(220,24,52)'/%3E%3C/svg%3E");
}

.attachment .attachment__link,
.attachment .attachment__link:link,
.attachment .attachment__link:visited {
  text-decoration: none;
}

.attachment .attachment__title:hover,
.attachment .attachment__title:focus,
.attachment .attachment__title:active {
  text-decoration: underline;
}

.blender {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-position: 50% 50%;
  background-size: cover;
}

.blender__container {
  position: relative;
  background: #292A2D url(/images/local/blender/v-202503271106/0.jpg) no-repeat 50% 50%/cover;
  display: none;
}

@media screen and (min-width: 600px) {
  .blender__container {
    display: block;
    padding-bottom: 315px;
  }
}

@media screen and (min-width: 1024px) {
  .blender__container {
    padding-bottom: 31.25%;
  }
}

@media screen and (min-width: 1920px) {
  .blender__container {
    padding-bottom: 590px;
  }
}

.blender__buttons {
  position: absolute;
  z-index: 15;
  right: 3%;
  bottom: 2rem;
  display: flex;
}

.blender__buttons[data-blender-buttons] {
  right: 50%;
  transform: translateX(50%);
}

.blender__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 0;
  background: transparent none;
  box-shadow: none;
  width: 50px;
  height: 50px;
  background-color: #bfbfbf;
  cursor: pointer;
}

.blender__button:first-of-type {
  background-color: #dddddd;
}

.blender__button svg {
  fill: #292A2D;
}

.blender__button:hover, .blender__button:focus, .blender__button:active {
  background-color: #003fcf;
  color: #ffffff;
}

.blender__button:hover svg, .blender__button:focus svg, .blender__button:active svg {
  fill: #ffffff;
}

@keyframes blend-in {
  from {
    display: block;
    opacity: 0;
  }
  to {
    display: block;
    opacity: 1;
  }
}

.blender__item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.blender__item.-animating {
  animation: blend-in 800ms ease-out;
  animation-fill-mode: forwards;
}

.blender__title, .blender__description {
  position: absolute;
  right: 0em;
  bottom: 4%;
  z-index: 51;
}

.blender__description {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 35.8em;
  padding: 1rem 1em 1rem 6%;
  max-width: 55%;
  height: 140px;
}

.blender__description a,
.blender__description a:link,
.blender__description a:visited {
  color: #ffffff;
  font-size: 1.3125rem;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
  margin: 0 0 .25em;
  text-decoration: underline;
}

.blender__description a:hover,
.blender__description a:focus,
.blender__description a:active {
  text-decoration: none;
}

.blender__description strong {
  font-size: 1.3125rem;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
  margin: 0 0 .25em;
}

.blender__description span {
  font-size: 1rem;
}

.blender__description:before {
  position: absolute;
  content: "";
  top: 0px;
  bottom: 0px;
  left: 0;
  right: -40px;
  background-color: rgba(0, 0, 0, 0.75);
  transform: skew(-14.5deg);
  z-index: -1;
}

@media screen and (min-width: 600px) {
  .blender__container {
    display: block;
    padding-bottom: 210px;
    background: transparent url(/images/local/blender/v-202503271106/0-page.png) no-repeat 50% 50%/cover;
  }
}

@media screen and (min-width: 1024px) {
  .blender__container {
    padding-bottom: 20.83333%;
  }
}

@media screen and (min-width: 1920px) {
  .blender__container {
    padding-bottom: 302px;
  }
}

.additional {
  position: relative;
  padding: 0;
}

@media screen and (min-width: 750px) {
  .additional::after {
    background: transparent url(/images/local/v-202503271106/page-bottom-prechod.png) no-repeat center bottom;
  }
}
