.blender{
	&__container{
		@include min(600px) {
			display: block;
			padding-bottom: 210px;
			background: transparent url(/images/local/blender/v-202503271106/0-page.png) no-repeat 50% 50%/cover;
		}
		@include min(1024px) {
			padding-bottom: ratio(1920px, 400px);
		}
		@include min(1920px) {
			padding-bottom: 302px;
		}
	}
}

.additional {
	position: relative;
	padding: 0;
	@include min(750px){
		&::after{
			background: transparent url(/images/local/v-202503271106/page-bottom-prechod.png) no-repeat center bottom;
		}
	}
}
