.abo {
	&__form {
		.form__section {

		}

		.label {
			position: absolute !important;
			left: -9999rem !important;
			top: auto;
			outline: 0;
			width: 0;
			height: 0;
			overflow: hidden;
		}

		.input {
			background-color: $color-light;
			border-color: $color-dark;
			border-width: 0;
			color: $color-dark;
			min-height: 55px;
			box-shadow: 0 0 0;
			max-width: 380px;
		}

		.gdpr-agreement {
			margin: 0;
		}
		.button.-primary{
			background-color: transparent;
			padding: 0;
			min-height: 0;
			font-weight: 700;
			@include link-over(&){
				background-color: transparent;
				text-decoration: underline;
			}
		}
	}
}
