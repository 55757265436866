.tiles {
	&__list {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		margin: 3rem 0 2rem;
		list-style-type: none;
		@include grid(500px 2 1.5rem, 1024px 3);
		.-nosidebar & {
			@include grid(500px 2 1.5rem, 1024px 4);
		}
	}
}

.tile {
	&__item {
		width: 100%;
		font-size: rem(20px);
		font-weight: 800;
	}


	&__link {
		position: relative;
		padding: 1.25rem 1rem;
		@include flex(df, aic, jcc);
		min-height: 80px;
		text-align: center;
		transition: background-color .3s ease-in-out, color .3s ease-in-out, box-shadow .3s ease-in-out;
		box-shadow: 0 0 0 -25px;
		@include min(1024px){
			min-height: 132px;
		}

		@include link(&) {
			background-color: #fff;
			color: $color-secondary;
			text-decoration: none;
		}

		@include link-over(&) {
			background-color: $color-tercialy;
			color: $color-dark;
			box-shadow: 0px 35px 25px -25px rgba(#000000, .25);
		}
	}
}
