.blender {
	@include fill;
	z-index: 2;
	background-position: 50% 50%;
	background-size: cover;

	&__container {
		position: relative;
		background: $color-dark url(/images/local/blender/v-202503271106/0.jpg) no-repeat 50% 50% / cover;
		display: none;

		@include min(600px) {
			display: block;
			padding-bottom: 315px;
		}
		@include min(1024px) {
			padding-bottom: ratio(1920px, 600px);
		}
		@include min(1920px) {
			padding-bottom: 590px;
		}
	}

	&__buttons {
		position: absolute;
		z-index: 15;
		right: 3%;
		bottom: 2rem;
		display: flex;
		&[data-blender-buttons]{
			right: 50%;
			transform: translateX(50%);
		}
	}

	&__button {
		@include flex(df,aic,jcc);
		padding: 0;
		border-radius: 0;
		background: transparent none;
		box-shadow: none;
		width: 50px;
		height: 50px;
		background-color: #bfbfbf;
		cursor: pointer;
		&:first-of-type {
			background-color: #dddddd;
		}
		svg{
			fill: $color-dark;
		}
		@include link-over(&){
			background-color: $color-secondary;
			color: $color-light;
			svg{
				fill: $color-light;
			}
		}
	}


	@keyframes blend-in {
		from {
			display: block;
			opacity: 0;
		}

		to {
			display: block;
			opacity: 1;
		}
	}

	&__item {
		@include fill;

		background: {
			position: 50% 50%;
			repeat: no-repeat;
			size: cover;
		}

		&.-animating {
			animation: blend-in 800ms ease-out;
			animation-fill-mode: forwards;
		}
	}

	&__title,
	&__description {
		position: absolute;
		right: 0em;
		bottom: 4%;
		z-index: 51;
	}

	&__description {
		color: #ffffff;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 35.8em;
		padding: 1rem 1em 1rem 6%;
		max-width: 55%;
		height: 140px;

		@include link {
			color: #ffffff;
			font-size: rem(21px);
			font-weight: 600;
			font-family: $font-headings;
			margin: 0 0 .25em;
			text-decoration: underline;
		}

		@include link-over {
			text-decoration: none;
		}

		strong {
			font-size: rem(21px);
			font-weight: 600;
			font-family: $font-headings;
			margin: 0 0 .25em;
		}

		span {
			font-size: rem(16px);
		}

		&:before {
			position: absolute;
			content: "";
			top: 0px;
			bottom: 0px;
			left: 0;
			right: -40px;
			background-color: rgba(#000000, .75);
			transform: skew(-14.5deg);
			z-index: -1;
		}
	}
}
